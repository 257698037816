<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :collapseHeight="60" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="订单号：" label-width="60px" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入订单号"></el-input>
        </el-form-item>
        <el-form-item label="订单类型：" prop="category">
          <el-select v-model="category" placeholder="请选择">
            <el-option v-for="item in orderCategoryOptions" :key="item.id" :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      orderCategoryOptions: [
        { name: "会费", id: 0 },
        { name: "活动报名（付费报名）", id: 1 },
        { name: "活动报名（保证金报名）", id: 4 },
        { name: "资源圈（置顶）", id: 2 },
        { name: "资源圈（付费内容）", id: 3 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    category: {
      get() {
        return this.form.category == -1 ? "" : this.form.category;
      },
      set(val) {
        this.form.category = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>