<template>
  <div class="body">
    <div style="margin-bottom: 27px">
      <list-filter v-model="filterForm" :uploadFilter="ok" />
    </div>
    <div class="list-content">
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%" v-loading="loading"
        @sort-change="sortChange">
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :minWidth="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <!-- 普通 -->
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import listFilter from "../../components/Member/MyOrderFilter";
import Pagination from "@/base/components/Default/Pagination";
import { myOrderList } from "../../api/member/member-detail";
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "ID", prop: "user_id", minWidth: 80 },
        { label: "会员名称", prop: "username", minWidth: 110 },
        { label: "会内职务", prop: "title_name", minWidth: 180 },
        { label: "订单类型", prop: "order_category", minWidth: 130 },
        {
          label: "积分/金额(元)",
          prop: "price",
          minWidth: 140,
          sortable: true,
        },
        { label: "订单号", prop: "orderid", minWidth: 150 },
        { label: "订单状态", prop: "order_status", minWidth: 110 },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
      ],
      //筛选对象
      filterForm: {
        id: this.data?this.$deCode(this.data).id:this.$route.params.user_id,
        category: -1, //内容分类
        keyword: "",
        page_size: 15, //每页数据数
      },
      tbody: [],
      pageData: {},
    };
  },
  methods: {
    // 获取列表数据
    getMyOrderList(pageData) {
      this.loading = true;
      myOrderList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getMyOrderList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getMyOrderList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getMyOrderList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getMyOrderList(pageData);
    },
  },
  created() {
    this.getMyOrderList(this.filterForm); //获取列表数据
  },
  components: { listFilter, Pagination },
};
</script>

<style lang="scss" scoped>
.body {
  min-height: calc(100vh - 270px);
  .tool-tip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .replay-status {
    display: flex;
    align-items: center;

    .replayed {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      background: rgb(8, 243, 39);
    }

    .no-replay {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      background: rgba(255, 203, 44, 1);
    }
  }
}
</style>
